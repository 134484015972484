import "./Work.css";
import rxs from "./58B44942-0D92-49E2-8DBE-83D699B07E1C_1_201_a.jpeg";

import { motion } from "framer-motion";
const projectsText = `Below, I present a selection of websites I've developed:`;

const projectsList = [
  {
    name: "RxS Sports Association - Football Academy",
    image: rxs,
    imgName: "PartyEvents",
    link: "https://asociatia-sportiva-rxs.onrender.com",
  },
];

export default function Work() {
  return (
    <section id="work">
      <h2 className="projectTitle">Work</h2>
      <span className="projectsText">{projectsText}</span>
      <div className="projectImgs">
        {projectsList.map((project) => (
          <div className="container-work">
            {project.name}
            <a href={project.link}>
              <motion.img
                whileHover={{ scale: 1.03 }}
                key={project.name}
                src={project.image}
                alt={project.imgName}
                className="projImg-work"
              />
            </a>
          </div>
        ))}
      </div>
    </section>
  );
}
